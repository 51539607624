import { useTranslation } from "next-i18next";
import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import everythingyouneedStyle from "./everythingyouneed.module.scss";

const EverythingYouNeedSection = ({ data, assetsUrl }) => {
  const { t } = useTranslation("default");
  return (
    <>
      <section className="everything-you-need-section section">
        <Container>
          <Row className="align-items-center">
            <Col md={6} className="position-relative">
              <Image
                className="img-resize"
                fluid="true"
                src={assetsUrl + data?.imageUrl}
                alt={t(data?.title)}
              />
            </Col>
            <Col md={6}>
              <div className={everythingyouneedStyle.content}>
                <h2 className="default-heading-2 !text-white">{t(data?.title)}</h2>
                <p>{t(data?.subtitle)}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default EverythingYouNeedSection;

import React from "react";

import Countdown from "../../common/countdown";
import VideoSlider from "../../common/videoSlider";
import Footer from "../footer";
import Header from "../header";
import EverythingYouNeedSection from "../sections/everythingYouNeed";
import Faqs from "../sections/faqs";
import GettingStartedSection from "../sections/gettingStarted";
import Jumbotron from "../sections/jumbotron";
import ProgramBenefitsSection from "../sections/programBenefits";
import SurveyNowSection from "../sections/surveyNow";
import Terminated from "../sections/terminated";

const DefaultLayout = ({ clientObj, data, clientConfig, assetsUrl = "" }) => {
  return data && Array.isArray(data.sections) ? (
    <>
      {data.sections.map((section, index) => {
        if (
          clientConfig.hasOwnProperty("isTerminated") &&
          clientConfig.isTerminated &&
          !["header", "footer"].includes(section?.type)
        )
          return;
        switch (section?.type) {
          case "header":
            return (
              <>
                <Header
                  key={index}
                  data={section?.providedText}
                  clientLogo={clientConfig?.clientLogo}
                  assetsUrl={assetsUrl}
                />
                {clientConfig.hasOwnProperty("isTerminated") && clientConfig.isTerminated && (
                  <Terminated
                    key={index}
                    data={section?.providedText}
                    assetsUrl={assetsUrl}
                    clientName={clientConfig.clientName}
                  />
                )}
              </>
            );

          case "banner":
            return (
              <Jumbotron key={index} data={section?.props} clientObj={clientObj} assetsUrl={assetsUrl} />
            );

          case "countdown":
            return <Countdown key={index} data={section?.props} layoutType="default" assetsUrl={assetsUrl} />;

          case "waveSection":
            return <ProgramBenefitsSection key={index} data={section?.props} assetsUrl={assetsUrl} />;

          case "gettingStarted":
            return <GettingStartedSection key={index} data={section?.props} assetsUrl={assetsUrl} />;

          case "everythingYouNeed":
            return <EverythingYouNeedSection key={index} data={section?.props} assetsUrl={assetsUrl} />;

          case "surveySection":
            return (
              <SurveyNowSection
                key={index}
                data={section?.props}
                clientObj={clientObj}
                assetsUrl={assetsUrl}
              />
            );

          case "videoSection":
            return (
              <VideoSlider
                key={index}
                data={section?.props}
                isWave
                headingClassName="default-heading-2"
                sectionBgColor="care-first-primary-bg"
                layoutType="default"
                assetsUrl={assetsUrl}
              />
            );

          case "faqSection":
            return <Faqs key={index} data={section?.props} assetsUrl={assetsUrl} />;

          case "footer":
            return <Footer key={index} data={section?.props} assetsUrl={assetsUrl} />;

          default:
            break;
        }
      })}
    </>
  ) : null;
};

export default DefaultLayout;

import classNames from "classnames";
import { useTranslation } from "next-i18next";
import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import ProgrambenefitsStyle from "./programbenefits.module.scss";

const ProgramBenefitsSection = ({ data, assetsUrl }) => {
  const { t } = useTranslation("default");
  return (
    <>
      <div className="program-benefits-section-wrapper">
        <Image
          fluid="true"
          src={assetsUrl + "/images/default/svg/benefits-div.svg"}
          alt={t(data?.title)}
          className={classNames("d-none d-md-block", ProgrambenefitsStyle.programbenefitswaves)}
        />
        <section className="program-benefits-section">
          <Container>
            <Row>
              <Col md={12}>
                <h2 className="default-heading-2">{t(data?.title)}</h2>
              </Col>
            </Row>
            {data?.items && data?.items?.length && (
              <Row>
                {data?.items?.map((item, index) => {
                  return (
                    <Col md={4} key={index}>
                      <div
                        className={classNames(
                          "program-benefits-card",
                          ProgrambenefitsStyle.programbenefitscard
                        )}>
                        <div className={classNames(ProgrambenefitsStyle.programbenefitscard__image, "mb-4")}>
                          <Image fluid="true" src={assetsUrl + item?.imageUrl} alt={t(item?.title)} />
                        </div>
                        <p className={classNames(ProgrambenefitsStyle.programbenefitscard__header)}>
                          {t(item?.title)}
                        </p>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            )}
          </Container>
        </section>
        <Image
          fluid="true"
          src={assetsUrl + "/images/default/svg/benefits-bottom-mob.svg"}
          alt={t(data?.title)}
          className={`d-block d-md-none benefits-img-bottom ${ProgrambenefitsStyle.programbenefitswaves}`}
        />
      </div>
    </>
  );
};

export default ProgramBenefitsSection;

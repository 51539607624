import { getLinkWithClientID } from "@/lib/helpers";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

import jumbotronStyle from "./jumbotron.module.scss";

const Jumbotron = ({ data, clientObj, assetsUrl }) => {
  const { t } = useTranslation("default");

  return (
    <>
      <section className="default-jumbotron-section">
        <Container>
          <Row>
            <Col md={6} className="mb-md-0 mb-5">
              <div className={jumbotronStyle.jumbotron}>
                <h1 className="default-heading-1 md:!mb-10">
                  {t(data?.title1)} <br /> {t(data?.title2)}
                </h1>
                <p className="md:!mb-10">{t(data?.subtitle)}</p>
                <Link
                  href={getLinkWithClientID(data?.buttonCTA, clientObj?.id) || ""}
                  className="btn transform-start default-btn-primary">
                  {t(data["buttonText"])}
                </Link>
              </div>
            </Col>
            <Col md={6}>
              <Image
                className={`new-banner ${jumbotronStyle.jumbotronimage}`}
                fluid="true"
                src={assetsUrl + data?.imageUrl}
                alt={data?.imageAlt}
              />
              {data?.appDownloadLinks && data?.appDownloadLinks.length && (
                <div className={`apps mt-md-0 mt-4 ${jumbotronStyle.socialmedia}`}>
                  {data?.appDownloadLinks?.map((link, index) => {
                    return (
                      <Link href={link?.buttonCTA || ""} key={index}>
                        <Image fluid="true" src={assetsUrl + link?.imageUrl} alt={t(link?.imageAlt)} />
                      </Link>
                    );
                  })}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Jumbotron;

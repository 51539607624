import classNames from "classnames";
import React from "react";

// import GettingStartedStyle from "./gettingstarted.module.scss";
const GettingStartedCard = (props) => {
  const { title, desc, isActive, onHover, index } = props;

  return (
    <>
      <div
        className={classNames("gettingstartedcard", isActive && "active")}
        onMouseEnter={() => onHover(index)}>
        <span>
          <h4>{title}</h4>
        </span>
        <p>{desc}</p>
      </div>
    </>
  );
};

export default GettingStartedCard;

import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

const Footer = ({ data, assetsUrl }) => {
  const { t } = useTranslation("default");
  const currentYear = new Date().getFullYear();
  return (
    <>
      <footer className={classNames("section footer-section pb-3 text-brand-text-muted")}>
        <Container>
          <Row>
            <Col lg={12}>
              <div className={classNames("flex flex-col items-center")}>
                <Link href="/">
                  <Image
                    // className={headerStyle.siteLogo}
                    fluid="true"
                    src={assetsUrl + data?.logoImageUrl}
                    alt={t(data?.logoAlt)}
                  />
                </Link>
              </div>
            </Col>
            {data?.text1 && (
              <Col className="mb-6 flex justify-center" lg={12}>
                <p className="max-w-screen-sm text-center">{t(data?.text1)}</p>
              </Col>
            )}
          </Row>
          <Row className="mx-auto max-w-screen-sm font-normal">
            {data?.tollFreeIconUrl && (
              <Col md={6}>
                <Link
                  href={data?.tollFreeUrl || ""}
                  className="social mb-4 flex justify-center text-sm font-medium !no-underline sm:text-base">
                  <div className="flex">
                    <Image
                      className="mr-2"
                      fluid="true"
                      src={assetsUrl + data?.tollFreeIconUrl}
                      alt={t(data?.tollFreeText)}
                    />
                    <span>{t(data?.tollFreeText)}</span>
                  </div>
                </Link>
              </Col>
            )}
            {data?.emailIconUrl && (
              <Col md={6}>
                <Link
                  href={`mailto:${t(data?.emailText)}`}
                  className="social mb-4 flex justify-center font-medium !no-underline">
                  <Image
                    className="mr-2"
                    fluid="true"
                    src={assetsUrl + data?.emailIconUrl}
                    alt={t(data?.emailText)}
                  />
                  {t(data?.emailText)}
                </Link>
              </Col>
            )}
            {data?.addressIconUrl && (
              <Col md={12}>
                <a className="social mb-4 flex cursor-pointer justify-center font-medium !no-underline">
                  <Image
                    className="mr-2"
                    fluid="true"
                    src={assetsUrl + data?.addressIconUrl}
                    alt={t(data?.addressText)}
                  />
                  {t(data?.addressText)}
                </a>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              {Array.isArray(data.socialLinks) && (
                <div className={classNames("mt-8 flex flex-col items-center")}>
                  <h3 className="!text-xl">{t(data?.socialMediaTitle)}</h3>
                  <div className="my-2 flex space-x-12">
                    {data.socialLinks.map((social) => {
                      return (
                        <a
                          key={social.buttonCTA}
                          href={social.buttonCTA}
                          className="transition-transform hover:scale-125"
                          target="_blank"
                          rel="noreferrer">
                          <Image fluid="true" src={assetsUrl + social?.imageUrl} alt={social?.imageAlt} />
                        </a>
                      );
                    })}
                  </div>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={classNames("mt-4 text-center text-xs font-medium md:mt-6")}>
                {data?.text2 && <p>{t(data?.text2)}</p>}
                {data?.text3 && <p>{t(data?.text3)}</p>}
                {data?.text4 && <p>{t(data?.text4, { currentYear: currentYear })}</p>}
                <p>
                  {t(data?.seeOur)}
                  &nbsp;
                  <a href={data?.privacyLink} target="_blank" rel="noreferrer">
                    {t(data?.privacyText)}
                  </a>
                  , &nbsp;
                  <a href={data?.privacyLink} target="_blank" rel="noreferrer">
                    {t(data?.privacyText)}
                  </a>
                  , &nbsp;
                  <a href={data?.termsLink} target="_blank" rel="noreferrer">
                    {t(data?.termsText)}
                  </a>
                  &nbsp; {t(data?.footerAnd)} &nbsp;
                  <a href={data?.informedLink} target="_blank" rel="noreferrer">
                    {t(data?.informedText)}
                  </a>
                </p>
                <p>
                  {data?.text5 && t(data?.text5, { currentYear: currentYear })}
                  <br /> <a href={`mailto:${t(data?.supportMail)}`}>{t(data?.supportMail)}</a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;

import { getLinkWithClientID } from "@/lib/helpers";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import surveynowStyle from "./surveynow.module.scss";

const SurveyNowSection = ({
  data,
  clientObj,
  // assetsUrl,
}) => {
  const { t } = useTranslation("default");
  return (
    <>
      <section className="surveynow-section section pb-0">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} md={8}>
              <div className={surveynowStyle.content}>
                <h2 className="default-heading-2">{t(data?.title)}</h2>
                <p>{t(data?.subtitle)}</p>
              </div>
            </Col>
            <Col lg={6} md={4} className="text-center">
              <Link
                href={getLinkWithClientID(data?.buttonCTA, clientObj?.id) || ""}
                className="btn default-btn-primary">
                {t(data?.buttonText)}
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SurveyNowSection;

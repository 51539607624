import { useTranslation } from "next-i18next";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

const Faqs = ({ data }) => {
  const { t } = useTranslation("default");
  return (
    <section className="default-faqs-section bg-default-grey section">
      <Container>
        <Row>
          <Col md={12}>
            <h2 className="default-heading-2">{t(data?.title)}</h2>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Accordion defaultActiveKey="0" flush>
              {data?.items.map((faq, index) => {
                return (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header>{t(faq.question)}</Accordion.Header>
                    <Accordion.Body
                      dangerouslySetInnerHTML={{
                        __html: t(faq.answer).replace(/\n/g, "<br/>"),
                      }}
                    />
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Faqs;

import { useTranslation } from "next-i18next";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Terminated = ({ clientName }) => {
  const capitalize = (str, translatedStr) => {
    const arr = str.replace(/_/g, " ").split(" ");
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const strCapitalized = arr.join(" ");
    const translatedStrReplaced = translatedStr.replace("*", strCapitalized);
    return translatedStrReplaced;
  };

  const { t } = useTranslation("default");
  return (
    <section className="default-terminated-section bg-default-grey section">
      <Container>
        <Row>
          <Col>
            <h2 className="default-heading-2 mb-0">{capitalize(clientName, t("terminatedTitle"))}</h2>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Terminated;

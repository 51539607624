import { useTranslation } from "next-i18next";
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import GettingStartedCard from "../../cards/gettingStarted";
import GettingStartedStyle from "./gettingstarted.module.scss";

const GettingStartedSection = ({ data, assetsUrl }) => {
  const { t } = useTranslation("default");
  const [active, setActive] = useState(0);

  return (
    <>
      <section className="getting-started-section">
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="default-heading-2">{t(data?.title)}</h2>
            </Col>
          </Row>
          {data?.steps && (
            <Row className="align-items-center">
              <Col md={6}>
                <div
                  className={GettingStartedStyle.images}
                  style={{ backgroundImage: `url("${assetsUrl + data?.steps[active]?.imageUrl}")` }}
                />
              </Col>
              <Col md={6}>
                {data?.steps?.map((step, index) => {
                  return (
                    <GettingStartedCard
                      key={index}
                      index={index}
                      isActive={index === active}
                      title={t(step?.title)}
                      desc={t(step?.subTitle)}
                      onHover={setActive}
                    />
                  );
                })}
              </Col>
            </Row>
          )}
        </Container>
      </section>
    </>
  );
};

export default GettingStartedSection;

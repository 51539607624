import LanguageSelector from "@/components/LanguageSelector";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import headerStyle from "./header.module.scss";

const Header = ({ data, clientLogo, assetsUrl }) => {
  const { t } = useTranslation("default");

  return (
    <header className="default-home-header">
      <Container>
        <Row>
          <Col lg={12}>
            <div className={classNames("flex items-center justify-between py-3.5 md:py-11")}>
              <div className="flex flex-col md:flex-row">
                <Link href="/">
                  <Image
                    className={headerStyle.siteLogo}
                    fluid="true"
                    src={assetsUrl + "/svg/simple-therapy-logo.svg"}
                    alt="Simple Therapy"
                  />
                </Link>
                <div className={classNames(headerStyle["provided-by"])}>
                  <p className={classNames("default-home-para", headerStyle["para"])}>{t(data)}</p>
                  <Image
                    className={headerStyle.brandLogo}
                    fluid="true"
                    src={clientLogo?.url || assetsUrl + data?.imageUrl}
                    alt={clientLogo?.name}
                  />
                </div>
              </div>
              <LanguageSelector className="shrink-0" size="lg" shortAbbreviation />
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;

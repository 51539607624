import { checkImageSrc } from "@/lib/helpers";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Slider from "react-slick";

const settings = {
  dots: false,
  arrow: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
};

const VideoSlider = (props) => {
  const { isWave, headingClassName, sectionBgColor, data, layoutType, assetsUrl } = props;
  const { t } = useTranslation(layoutType);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (window === undefined) return;
    if (data?.items?.length === 0) return;

    const slides = data.items.map((item, i) => ({
      ...item,
      id: i + 1,
      imageSrc: item?.imageUrl.includes("http") ? item?.imageUrl : assetsUrl + item?.imageUrl,
    }));
    Promise.all([...slides.map((slide) => checkImageSrc(slide.imageSrc))]).then((arrayOfResults) => {
      if (!isMounted) return;
      const validSlides = slides.filter((slide) => arrayOfResults[slide.id - 1] === true);
      if (validSlides.length === 0) return;
      setSlides(validSlides);
    });

    return () => {
      isMounted = false;
    };
  }, [data?.items]);

  return slides.length > 0 ? (
    <div className="video-section-wrapper">
      {isWave ? (
        <Image fluid="true" src={assetsUrl + "/images/default/svg/benefits-div.svg"} alt={t(data.title)} />
      ) : null}
      <section className={classNames("video-section section", sectionBgColor)}>
        <Container>
          <Row>
            <Col md={12}>
              <h2 className={classNames(headingClassName)}>{t(data.title)}</h2>
            </Col>
          </Row>
          <Row>
            <div className="video-slider-card-wrapper">
              <Slider {...settings}>
                {slides.map((slide) => (
                  <div key={slide.id}>
                    <div className="video-slider-card">
                      <Link href={slide.videoUrl || ""} target="_blank" className="position-relative">
                        <span>
                          <span className="video-slider-card__highlightname d-none">{slide.name}</span>
                          <span className="video-slider-card__logo d-none" />
                          <span className="video-slider-card__videoicon d-none" />
                          <Image fluid="true" src={slide.imageSrc} alt={slide.name} />
                        </span>
                      </Link>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </Row>
        </Container>
      </section>
    </div>
  ) : null;
};

export default VideoSlider;

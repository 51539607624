import { useTranslation } from "next-i18next";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";

// import countdownStyle from "./countdown.module.scss";

const Countdown = ({ data, layoutType, assetsUrl }) => {
  const { t } = useTranslation(layoutType);

  const [formattedDate, setFormattedDate] = useState(null);
  const [formattedDays, setFormattedDays] = useState(null);
  const [formattedHours, setFormattedHours] = useState(null);
  const [formattedMinutes, setFormattedMinutes] = useState(null);
  const [formattedSeconds, setFormattedSeconds] = useState(null);

  useEffect(() => {
    const updateTime = setInterval(() => {
      const launchDate = new Date(data?.launchDate);
      const diffTime = launchDate.getTime() - new Date().getTime();

      let diffDays = diffTime > 0 ? Math.trunc(diffTime / (1000 * 60 * 60 * 24)) : 0;
      let diffHours = diffTime > 0 ? Math.trunc((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) : 0;
      let diffMinutes = diffTime > 0 ? Math.trunc((diffTime % (1000 * 60 * 60)) / (1000 * 60)) : 0;
      let diffSeconds = diffTime > 0 ? Math.trunc((diffTime % (1000 * 60)) / 1000) : 0;

      if (diffDays < 10) diffDays = "0" + diffDays;
      if (diffHours < 10) diffHours = "0" + diffHours;
      if (diffMinutes < 10) diffMinutes = "0" + diffMinutes;
      if (diffSeconds < 10) diffSeconds = "0" + diffSeconds;

      setFormattedDays(diffDays);
      setFormattedHours(diffHours);
      setFormattedMinutes(diffMinutes);
      setFormattedSeconds(diffSeconds);
      setFormattedDate(launchDate.toLocaleDateString("en-US"));
    }, 1000);

    return () => {
      clearInterval(updateTime);
    };
  }, []);

  return (
    <>
      <section className="countdown-section section position-relative d-flex justify-content-center p-0">
        <Image
          alt=""
          className="countdown-img"
          fluid="true"
          src={
            data?.backgroundImageURL?.includes("http")
              ? data?.backgroundImageURL
              : assetsUrl + data?.backgroundImageURL
          }
        />
        <div className="d-flex flex-column justify-content-center align-items-center countdown-container">
          <h2 className="default-heading-2 countdown-title">
            {t(data?.title)} {formattedDate}
          </h2>
          <div className="d-flex justify-content-center">
            <div className="d-flex flex-column align-items-center time-container">
              <h1 className="default-heading-1">{formattedDays}</h1>
              <h3 className="default-heading-3">{t(data?.text1)}</h3>
            </div>

            <div className="time-divider">
              <h1 className="default-heading-1">:</h1>
            </div>

            <div className="d-flex flex-column align-items-center time-container">
              <h1 className="default-heading-1">{formattedHours}</h1>
              <h3 className="default-heading-3">{t(data?.text2)}</h3>
            </div>

            <div className="time-divider">
              <h1 className="default-heading-1">:</h1>
            </div>

            <div className="d-flex flex-column align-items-center time-container">
              <h1 className="default-heading-1">{formattedMinutes}</h1>
              <h3 className="default-heading-3">{t(data?.text3)}</h3>
            </div>

            <div className="time-divider">
              <h1 className="default-heading-1">:</h1>
            </div>

            <div className="d-flex flex-column align-items-center time-container">
              <h1 className="default-heading-1 time-seconds">{formattedSeconds}</h1>
              <h3 className="default-heading-3">{t(data?.text4)}</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Countdown;
